
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import frenchLocale from 'date-fns/locale/fr';
import { IPlanificationProfileScheduler } from '@/entity/planification/planification-profile-scheduler';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import getDaysInYear from 'date-fns/fp/getDaysInYear/index.js';
import Affectations from './affectations/affectations.vue';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { format, getDaysInMonth, max, min, differenceInDays } from 'date-fns';
import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { IPlanificationPhaseProfile } from '@/entity/planification/planification-phase-profile';
import CalendarRow from './calendar-row.vue';
import { IEmployeeRole } from '@/entity/shared/referential';
import InputElementMgt from '@c/shared/input-element-mgt.vue';
import BaseLoader from '@/components/shared/base-loader.vue';

@Component({
    components: {
        Affectations,
        CalendarRow,
        InputElementMgt
    }
})
export default class RecapCalendar extends Vue {
    /* eslint-disable */
    @Prop({ required: true }) planificationPhases!: NU<IPlanificationPhase[]>;
    @Prop({ required: true }) promiseSave!: boolean;
    @Prop({ required: true }) isReadonly!: boolean;

    private allocationsShown: boolean = false;
    private allocationsCompareShown: boolean = false;
    private fullDay: boolean | null = true;
    private oldFullDay: boolean | null = this.fullDay;

    private colors: string[] = ['#ff638480', '#36a2eb70', '#cc65fe75', '#ffce568c', '#28a74563', '#17a2b87d'];
    planificationProfileSchedulersItems: IPlanificationProfileScheduler[] = this.planificationProfileSchedulers;

    @Watch('fullDay')
    private updateOldValue(newVal: boolean, oldVal: boolean) {
        if (oldVal != null) {
            this.oldFullDay = oldVal;
        } else {
            this.oldFullDay = newVal;
        }
    }

    @Watch('planificationProfileSchedulers')
    private updateEditingComment(newItem: IPlanificationProfileScheduler[]) {
        this.planificationProfileSchedulersItems = newItem;
    }

    showAllocations() {
        this.allocationsShown = true;
    }

    hideAllocations() {
        this.allocationsShown = false;
    }

    showAllocationsCompare() {
        this.allocationsCompareShown = true;
    }

    hideAllocationsCompare() {
        this.allocationsCompareShown = false;
    }

    filterUserIds: string[] = [];
    currentYear = new Date().getFullYear();
    selectedPlanificationPhases: number[] = [];

    monthGroups: Array<any> = [
        { id: 0, label: 'Janvier', days: [] },
        { id: 1, label: 'Février', days: [] },
        { id: 2, label: 'Mars', days: [] },
        { id: 3, label: 'Avril', days: [] },
        { id: 4, label: 'Mai', days: [] },
        { id: 5, label: 'Juin', days: [] },
        { id: 6, label: 'Juillet', days: [] },
        { id: 7, label: 'Août', days: [] },
        { id: 8, label: 'Septembre', days: [] },
        { id: 9, label: 'Octobre', days: [] },
        { id: 10, label: 'Novembre', days: [] },
        { id: 11, label: 'Décembre', days: [] }
    ];

    daysInMonths: any = {};
    currentModifiedPhaseId: number = -1;

    private fields: Array<IBootstrapTableColumn> = [
        {
            label: '',
            key: 'empty',
            thStyle: 'text-align:center;',
            tdClass: 'phase-calendar-class',
            stickyColumn: true
        },
        {
            label: 'Phase',
            key: 'contractLineLabel',
            thStyle: 'width:40%; text-align:center; background-color:red',
            tdClass: 'phase-calendar-class',
            stickyColumn: true
        },
        {
            label: 'Début',
            key: 'startDate',
            thStyle: 'width:10%; text-align:center;',
            stickyColumn: true
        },
        {
            label: 'Fin',
            key: 'endDate',
            thStyle: 'width:10%; text-align:center;',
            stickyColumn: true
        },
        {
            label: 'Prévisionnel',
            key: 'getPhaseTotalRate',
            thStyle: 'width:10%; text-align:center;',
            stickyColumn: true
        },
        {
            label: 'Budget',
            key: 'budget',
            thStyle: 'width:10%; text-align:center;',
            stickyColumn: true
        }
    ];


    private daysOfWork: Array<any> = [];

    get planificationPhasesOptions(): ISelectListOption[] {
        const res: ISelectListOption[] = [];
        this.planificationProfileSchedulersItems.forEach((element) => {
            if (res.find((x) => x.id === element.contractLineId) === undefined) {
                res.push({
                    id: element.contractLineId,
                    code: 'CO' + element.contractLineId,
                    label: element.contractLineLabel
                } as ISelectListOption);
            }
        });
        return res;
    }

    generateLightColorRgb(): string {
        const red = Math.floor(((1 + Math.random()) * 256) / 2);
        const green = Math.floor(((1 + Math.random()) * 256) / 2);
        const blue = Math.floor(((1 + Math.random()) * 256) / 2);
        return 'rgb(' + red + ', ' + green + ', ' + blue + ')';
    }


    get planificationProfileSchedulers(): IPlanificationProfileScheduler[] {
        const res: IPlanificationProfileScheduler[] = [];
        let indexColor: number = 0;
        this.planificationPhases?.forEach((phase) => {
            let totaleRateByPhase = 0;
            phase.planificationPhaseProfiles.forEach((element) => {
                totaleRateByPhase += element.totalRate;
            });
            const schedulerParent = {} as IPlanificationProfileScheduler;
            schedulerParent.indexInPhase = -1;
            schedulerParent.employeeId = '';
            schedulerParent.profileId = 0;
            schedulerParent.profileNumber = phase.planificationPhaseProfiles.length;
            schedulerParent.contractLineId = phase.contractLineId;
            schedulerParent.contractLineLabel = phase.contractLine?.designation ?? '';
            schedulerParent.isProvisional = phase.contractLine?.isProvisional ?? false;
            schedulerParent.statusCode = phase.contractLine?.status?.code ?? '';;
            schedulerParent.startDate = phase.openingDate;
            schedulerParent.endDate = phase.closingDate;
            schedulerParent.budget = phase.budget;
            schedulerParent.totalRate = totaleRateByPhase;
            schedulerParent.planificationPhaseProfileWorkDays = [];
            schedulerParent.phaseId = phase.id;
            schedulerParent.parentPhaseId = 0;
            schedulerParent.visible = true;
            schedulerParent.collapsedPhase = true;
            res.push(schedulerParent);
            phase.planificationPhaseProfiles.forEach((profile, index) => {
                const backgroundColor = this.generateLightColorRgb();
                const scheduler = {} as IPlanificationProfileScheduler;
                scheduler.indexInPhase = index;
                const dates = profile.planificationPhaseProfileWorkDays?.map((x) => new Date(x.date));
                if (dates && dates.length > 0) {
                    scheduler.employeeId = profile.employeeId ?? '';
                    scheduler.profileId = profile.id;
                    scheduler.profileRole = profile.employeeRole?.label;
                    scheduler.contractLineId = phase.contractLineId;
                    scheduler.backgroundColor = this.colors[indexColor] ?? backgroundColor;
                    scheduler.contractLineLabel = phase.contractLine?.designation ?? '';
                    scheduler.statusCode = phase.contractLine?.status?.code ?? '';
                    scheduler.isProvisional = phase.contractLine?.isProvisional ?? false;
                    scheduler.startDate = min(dates);
                    scheduler.endDate = max(dates);
                    scheduler.budget = phase.budget;
                    scheduler.totalRate = profile.totalRate;
                    scheduler.planificationPhaseProfileWorkDays = profile.planificationPhaseProfileWorkDays;
                    scheduler.phaseId = 0;
                    scheduler.parentPhaseId = phase.id;
                    scheduler.visible = false;
                    scheduler.employee = profile.employee;
                    res.push(scheduler);
                    indexColor++;
                }
            });
        });
        return res;
    }

    get listOfHeaderMonth(): Array<any> {
        // to shift a number of cell in the header as big as the number of mission fields
        const list = [{ id: 12, label: '', days: [{}, {}, {}, {}, {}] }];
        return list.concat(this.monthGroups);
    }


    @Watch('currentYear')
    private fetchDaysOfMonths(year: number) {
        const data: Array<any> = [];
        const countDays: number = 1;
        this.monthGroups.forEach((month) => {
            month.days = [];
            data.push(month);
            for (let j = 0; j < getDaysInMonth(new Date(this.currentYear, month.id, 1)); j++) {
                month.days.push({
                    id: j + 1,
                    label: j + 1 + '',
                    currentDate: new Date(this.currentYear, month.id, j + 1),
                    globalId: this.buildIdDays(month.id + 1, j + 1)
                });
            }
        });
        this.daysInMonths = data;
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    buildIdDays(monthNumber: number, dayNumber: number): string {
        var month: string = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
        var day: string = dayNumber < 10 ? `0${dayNumber}` : `${dayNumber}`;
        return `${month}${day}`;
    }

    buildCalendar(currentDay: any): string {
        var classDay = this.buildIdDays(currentDay.currentDate.getMonth() + 1, currentDay.currentDate.getDate());
        const str = `xday-${classDay} eachDay ${
            this.isDayOfWeek(currentDay.currentDate.getMonth(), currentDay.currentDate.getDate()) ? 'weekEnd' : ''
        }`;
        return `${str}`;
    }


    get employeeRoles(): NU<IEmployeeRole[]> {
        return vxm.referential.employeeRoles;
    }

    getPhaseTotalRate(phaseId): number {
        let res = 0;
        this.planificationProfileSchedulersItems?.filter(x=>x.parentPhaseId==phaseId)?.forEach((element) => {
            res += element.totalRate;
        });
        return res;
    }

    private setDateforPlanification(plan: IPlanificationPhase) {
        var finalDates: Date[] = [];
        plan.planificationPhaseProfiles.forEach((p) => {
            var dates: Date[] = p.planificationPhaseProfileWorkDays.map((x) => new Date(x.date));
            if (dates && dates.length > 0) {
                finalDates = finalDates.concat(dates);
            }
        });
        if (finalDates && finalDates.length > 0) {
            plan.openingDate = min(finalDates);
            Vue.set(plan, 'openingDate', min(finalDates));
            plan.closingDate = max(finalDates);
            Vue.set(plan, 'closingDate', max(finalDates));
        }
    }
 getStatus() {
    
 }
    getDayFromSTringDate(value: string): number {
        const date = new Date(value);
        return date.getDate();
    }

    get currentFields(): any[] {
        const items = [] as any[];
        const year = this.currentYear;
        // For each day of selected year create a list of unique keys representing the day - ie: 'YYYY-MM-DD'
        // start on january 1st
        const currentProcessingDay = new Date(year, 0, 1);
        let maxIterations = 0;
        while (
            currentProcessingDay.getFullYear() === year &&
            maxIterations <= getDaysInYear(new Date(this.currentYear))
        ) {
            const key = `${new Date(
                currentProcessingDay.getFullYear(),
                currentProcessingDay.getMonth(),
                currentProcessingDay.getDate()
            ).toISOString()}`;
            // add ordered keys to items list
            items.push({
                key,
                label: currentProcessingDay.getDate().toString(),
                thStyle: 'min-width:18px;text-align:center',
                tdClass: 'td-no-border'
            });
            // increment current Day of 1
            currentProcessingDay.setDate(currentProcessingDay.getDate() + 1);
            maxIterations++;
        }
        return items;
    }

    private selectedUserEvent(event: CustomEvent) {
        const idemps: string[] = event.detail.map((x: { id: string }) => x.id);
        if (event && event.detail && event.detail.length > 0) {
            var searchedcontractLineId: number[] = [];
            this.planificationProfileSchedulersItems.forEach((p) => {
                if (idemps.includes(p.employeeId)) {
                    searchedcontractLineId.push(p.contractLineId);
                }
            });
            this.planificationProfileSchedulersItems.forEach((p) => {
                if (searchedcontractLineId.includes(p.contractLineId)) {
                    if (p.parentPhaseId === 0 || idemps.includes(p.employeeId)) {
                        p.visible = true;
                    } else {
                        p.visible = false;
                    }
                } else {
                    p.visible = false;
                }
            });
        } else if (event && event.detail && event.detail.length === 0) {
            this.planificationProfileSchedulersItems.forEach((p) => {
                if (p.employeeId === '' && p.profileRole === undefined) {
                    p.visible = true;
                } else {
                    p.visible = false;
                }
            });
        }
    }

/*** Permet de faire scroller le calendrier à la position du mois courant ***/
    scrollCalendarToCurrentMonth() {
        this.$nextTick(function() {
            const topRightElement = this.$refs.tabRightTop as HTMLElement;
            const tabRightBottom = this.$refs.tabRightBottom as HTMLElement;
            const month = topRightElement.querySelectorAll(".eachMonth")[(new Date()).getMonth()];
            const positionLeft = topRightElement.getBoundingClientRect().left

            if (month.getBoundingClientRect().left - positionLeft < new Date().getMonth()) return

            tabRightBottom.scrollLeft = month.getBoundingClientRect().left - positionLeft;
            topRightElement.scrollLeft = month.getBoundingClientRect().left - positionLeft;
        })
    }
/*** /Permet de faire scroller le calendrier à la position du mois courant ***/
    async mounted() {
        this.fetchDaysOfMonths(this.currentYear);
    }

    get employeeIds(): string[] {
        return [
            ...new Set(
                this.planificationProfileSchedulersItems
                    .map((x) => x.employeeId.toUpperCase())
                    .filter((x) => x !== null && x !== undefined && x.length > 0)
            )
        ];
    }

    
    get groupPickerId() {
        if (process.env.NODE_ENV === 'production') {
            return 'e59b07b5-a6f9-4040-b817-bad6fe2f2f1b';
        }
        return null;
    }

    get filteredPlanificationProfileSchedulers(): IPlanificationProfileScheduler[] {
        const list = this.planificationProfileSchedulersItems.filter((x) => {
            return (
                x.visible === true ||
                (this.currentModifiedPhaseId >= 0 && x.parentPhaseId === this.currentModifiedPhaseId)
            );
        });

        if (
            this.filterUserIds &&
            this.filterUserIds.length > 0 &&
            this.selectedPlanificationPhases &&
            this.selectedPlanificationPhases.length > 0
        ) {
            return list.filter(
                (x) =>
                    this.filterUserIds.find((y) => y === x.employeeId) !== undefined &&
                    this.selectedPlanificationPhases.find((y) => y === x.contractLineId) !== undefined
            );
        } else if (this.filterUserIds && this.filterUserIds.length > 0) {
            return list.filter((x) => this.filterUserIds.find((y) => y === x.employeeId) !== undefined);
        } else if (this.selectedPlanificationPhases && this.selectedPlanificationPhases.length > 0) {
            return list.filter(
                (x) => this.selectedPlanificationPhases.find((y) => y === x.contractLineId) !== undefined
            );
        }
        return list;
    }

    formatedDate(date: Date): string {
        if (date) {
            return (
                format(new Date(String(date)), 'dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    isDayOfWeek(month: number, day: number): boolean {
        var year = this.currentYear;
        var date = new Date(year, month, day);
        var res = date.getDay();
        return res === 0 || res === 6;
    }

    private formattedCurrency(item: number): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            item
        );
    }

    collapsePhase(phaseId: number, isCollapsed: boolean): void {
        this.currentModifiedPhaseId = -1;
        this.planificationProfileSchedulersItems.forEach((element) => {
            if (element.phaseId === phaseId) {
                element.collapsedPhase = !isCollapsed;
            }
            if (element.parentPhaseId === phaseId) {
                element.visible = isCollapsed;
            }
        });
        this.scrollCalendarToCurrentMonth()
    }

    datesEqual(d1: Date, d2: Date): boolean {
        return (
            d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d1.getDate()
        );
    }

    async emitSaveData() {
        this.$emit('savePhase')
    }

    markDate(d: Date, item, dayPartTypeMask: number) {
        // debugger;
        // on ne marque pas les cases de phase
        var ds = d.toDateString();

        if (item.parentPhaseId === 0 || item.planificationPhaseProfileWorkDays.filter(i => new Date(i.date).toDateString() === ds).length == 2 && dayPartTypeMask == 3) {
            return;
        }
        // on ne marque pas les week-ends
        if (d.getDay() === 0 || d.getDay() === 6) {
            return;
        }

        this.currentModifiedPhaseId = item.parentPhaseId;

        item.planificationPhaseProfileWorkDays = item.planificationPhaseProfileWorkDays.filter(
            (i) =>
                new Date(i.date).toDateString() !== ds ||
                (new Date(i.date).toDateString() === ds && (i.dayPartTypeId & dayPartTypeMask) != 0)
        );

        const profileWorkDayExistedMor = item.planificationPhaseProfileWorkDays.find(i => {
            return new Date(i.date).toDateString() == new Date(d).toDateString() && i.dayPartTypeId == 1
        })

        if ((dayPartTypeMask & 1) === 1 && profileWorkDayExistedMor == undefined) {
            let dupe = item.planificationPhaseProfileWorkDays.find(
                (x) => new Date(x.date).toDateString() === ds && x.dayPartTypeId == 1
            );
            if (dupe == null) {
                item.planificationPhaseProfileWorkDays.push({
                    date: d,
                    planificationPhaseProfileId: item.profileId,
                    dayPartTypeId: 1,
                    id: 0
                });
            }
        }

        const profileWorkDayExistedAft = item.planificationPhaseProfileWorkDays.find(i => {
            return new Date(i.date).toDateString() == new Date(d).toDateString() && i.dayPartTypeId == 2
        })

        if ((dayPartTypeMask & 2) === 2 && profileWorkDayExistedAft == undefined) {
            item.planificationPhaseProfileWorkDays.push({
                date: d,
                planificationPhaseProfileId: item.profileId,
                dayPartTypeId: 2,
                id: 0
            });
        } else {
            let rem = item.planificationPhaseProfileWorkDays.findIndex(
                (x) => new Date(x.date).toDateString() === ds && x.dayPartTypeId == 2
            );
            if (rem > -1) {
                item.planificationPhaseProfileWorkDays.splice(rem, 1);
            }
        }

        const currentPlanification: IPlanificationPhase | undefined = this.planificationPhases?.find(p => p.id === item.parentPhaseId);

        const currentProfile: IPlanificationPhaseProfile | undefined = currentPlanification?.planificationPhaseProfiles[item.indexInPhase];

        if (currentProfile !== undefined) {
            currentProfile.planificationPhaseProfileWorkDays =
                currentProfile.planificationPhaseProfileWorkDays.filter(
                    (i) =>
                        new Date(i.date).toDateString() !== ds ||
                        (new Date(i.date).toDateString() === ds && (i.dayPartTypeId & dayPartTypeMask) != 0)
                );

            if ((dayPartTypeMask & 1) === 1) {
                let dupe = currentProfile.planificationPhaseProfileWorkDays.find(
                    (x) => new Date(x.date).toDateString() === ds && x.dayPartTypeId == 1
                );
                if (dupe == null) {
                    currentProfile.planificationPhaseProfileWorkDays.push({
                        date: d,
                        planificationPhaseProfileId: item.profileId,
                        dayPartTypeId: 1,
                        id: 0
                    });
                }
            }
            if ((dayPartTypeMask & 2) === 2) {
                currentProfile.planificationPhaseProfileWorkDays.push({
                    date: d,
                    planificationPhaseProfileId: item.profileId,
                    dayPartTypeId: 2,
                    id: 0
                });
            } else {
                let rem = currentProfile.planificationPhaseProfileWorkDays.findIndex(
                    (x) => new Date(x.date).toDateString() === ds && x.dayPartTypeId == 2
                );
                if (rem > -1) {
                    currentProfile.planificationPhaseProfileWorkDays.splice(rem, 1);
                }
            }
        }

        if (this.planificationPhases && this.planificationPhases.length > 0) {
            var planifToUpdate = this.planificationPhases.find((x) => {
                return x.id === item.parentPhaseId;
            });
            if (planifToUpdate) {
                this.setDateforPlanification(planifToUpdate);
                const parentPhase = this.planificationProfileSchedulersItems.find(
                    (x) => x.phaseId == item.parentPhaseId
                );
                if (parentPhase) {
                    parentPhase.startDate = new Date(planifToUpdate.openingDate!).toISOString();
                    Vue.set(parentPhase, 'startDate', parentPhase.startDate);
                    parentPhase.endDate = new Date(planifToUpdate.closingDate!).toISOString();
                    Vue.set(parentPhase, 'endDate', parentPhase.endDate);
                }
            }
        }

        this.$emit('setDateforProfile', item, item.indexInPhase);
        this.$emit('setProfileTotalRate', item, currentPlanification,item.indexInPhase);
    }

    selectedPeriod: any = {};

    selectPeriod(period) {
        if (period == null) {
            this.fullDay = this.fullDay != null ? this.fullDay : this.oldFullDay;
        } else {
            this.fullDay = null;
        }
        this.selectedPeriod = period;
    }

    removePeriod() {
        if (this.selectedPeriod) {
            const date = new Date(this.selectedPeriod.startDate);
            while (differenceInDays(date, this.selectedPeriod.endDate) <= 0) {
                this.markDate(date, this.selectedPeriod.item, 0);
                date.setDate(date.getDate() + 1);
            }
            this.selectedPeriod = undefined;
        }
    }

    emitIdToUpdate(id: number): void {
        this.$emit('phaseToUpdate', id);
    }

    handleScroll(event: Event): void {
        const self = this as any;
        const element = event.target as HTMLElement;
        self.$refs.tabRightTop.scrollLeft = element.scrollLeft;
        self.$refs.tabRightBottom.scrollTop = element.scrollTop;
    }

    get IsLeapYear(): boolean {
        var res = false;
        if (this.currentYear % 4 !== 0) {
            res = false;
        } else if (this.currentYear % 100 !== 0) res = true;
        else if (this.currentYear % 400 !== 0) res = false;
        else res = true;
        return res;
    }
}
